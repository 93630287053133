import { dew as _CONFIG_GETDew } from "./CONFIG_GET";
import { dew as _CONFIG_SETDew } from "./CONFIG_SET";
import { dew as _DELETEDew } from "./DELETE";
import { dew as _EXPLAINDew } from "./EXPLAIN";
import { dew as _LISTDew } from "./LIST";
import { dew as _PROFILEDew } from "./PROFILE";
import { dew as _QUERYDew } from "./QUERY";
import { dew as _RO_QUERYDew } from "./RO_QUERY";
import { dew as _SLOWLOGDew } from "./SLOWLOG";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pushQueryArguments = void 0;

  const CONFIG_GET = _CONFIG_GETDew();

  const CONFIG_SET = _CONFIG_SETDew();

  ;

  const DELETE = _DELETEDew();

  const EXPLAIN = _EXPLAINDew();

  const LIST = _LISTDew();

  const PROFILE = _PROFILEDew();

  const QUERY = _QUERYDew();

  const RO_QUERY = _RO_QUERYDew();

  const SLOWLOG = _SLOWLOGDew();

  exports.default = {
    CONFIG_GET,
    configGet: CONFIG_GET,
    CONFIG_SET,
    configSet: CONFIG_SET,
    DELETE,
    delete: DELETE,
    EXPLAIN,
    explain: EXPLAIN,
    LIST,
    list: LIST,
    PROFILE,
    profile: PROFILE,
    QUERY,
    query: QUERY,
    RO_QUERY,
    roQuery: RO_QUERY,
    SLOWLOG,
    slowLog: SLOWLOG
  };

  function pushQueryArguments(args, graph, query, options, compact) {
    args.push(graph);

    if (typeof options === "number") {
      args.push(query);
      pushTimeout(args, options);
    } else {
      args.push(options?.params ? `CYPHER ${queryParamsToString(options.params)} ${query}` : query);

      if (options?.TIMEOUT !== undefined) {
        pushTimeout(args, options.TIMEOUT);
      }
    }

    if (compact) {
      args.push("--compact");
    }

    return args;
  }

  exports.pushQueryArguments = pushQueryArguments;

  function pushTimeout(args, timeout) {
    args.push("TIMEOUT", timeout.toString());
  }

  function queryParamsToString(params) {
    const parts = [];

    for (const [key, value] of Object.entries(params)) {
      parts.push(`${key}=${queryParamToString(value)}`);
    }

    return parts.join(" ");
  }

  function queryParamToString(param) {
    if (param === null) {
      return "null";
    }

    switch (typeof param) {
      case "string":
        return `"${param.replace(/["\\]/g, "\\$&")}"`;

      case "number":
      case "boolean":
        return param.toString();
    }

    if (Array.isArray(param)) {
      return `[${param.map(queryParamToString).join(",")}]`;
    } else if (typeof param === "object") {
      const body = [];

      for (const [key, value] of Object.entries(param)) {
        body.push(`${key}:${queryParamToString(value)}`);
      }

      return `{${body.join(",")}}`;
    } else {
      throw new TypeError(`Unexpected param type ${typeof param} ${param}`);
    }
  }

  return exports;
}